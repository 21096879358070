var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "px-12 pt-8 pb-0" }, [
        _vm.isEdit
          ? _c("h3", [_vm._v("Mail Template aanpassen")])
          : _c("h3", [_vm._v("Mail Template aanmaken")])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "pa-8" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  label: "Naam (koppeling met mail-chimp)"
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("h3", { staticClass: "subtitle" }, [
                        _vm._v("vertalingen")
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "translation-row" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "label",
                              attrs: { cols: "2", sm: "2" }
                            },
                            [_vm._v("nederlands:")]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "10", sm: "10" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "" },
                                model: {
                                  value: _vm.titles.nl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.titles, "nl", $$v)
                                  },
                                  expression: "titles.nl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "translation-row" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "label",
                              attrs: { cols: "2", sm: "2" }
                            },
                            [_vm._v("frans:")]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "10", sm: "10" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "" },
                                model: {
                                  value: _vm.titles.fr,
                                  callback: function($$v) {
                                    _vm.$set(_vm.titles, "fr", $$v)
                                  },
                                  expression: "titles.fr"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "translation-row" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "label",
                              attrs: { cols: "2", sm: "2" }
                            },
                            [_vm._v("engels:")]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "10", sm: "10" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "" },
                                model: {
                                  value: _vm.titles.en,
                                  callback: function($$v) {
                                    _vm.$set(_vm.titles, "en", $$v)
                                  },
                                  expression: "titles.en"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mb-2" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" Annuleren ")]
          ),
          _vm.canEdit
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    text: "",
                    disabled: !_vm.isComplete
                  },
                  on: { click: _vm.save }
                },
                [
                  _vm.isEdit
                    ? _c("span", [_vm._v("Mail Template aanpassen")])
                    : _c("span", [_vm._v("Mail Template aanmaken")])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }