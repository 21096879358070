import { isEmpty, propOr, pathOr } from 'ramda';


export default function (mailTemplates) {
  if (isEmpty(mailTemplates)) {
    return [];
  }

  return mailTemplates;
}
//   return mailTemplates.map((template) => ({
//     ...template

//   }));
// }
